<template>
  <div>
    <div >
      <div class="home-position">
        <div class="home-NavMenu">
          <div style="color: #ffffff" class="logo">
<!--            <img src="../assets/image/logo.png" alt="">-->
            <span>翊泽科技</span>
          </div>
          <el-row>
            <div class="hidden-xs-only">
              <el-menu  background-color="black"
                        text-color="#ffffff" class="el-menu-demo"
                        :default-active="activeIndex"
                        active-text-color="#E74E2A" @select="handleSelect"
                        mode="horizontal">
                <el-menu-item index="/" path="Contactus" >首页</el-menu-item>
                <el-menu-item index="/about">关于我们</el-menu-item>
                <el-menu-item index="/CompanyHonor">公司荣誉</el-menu-item>
                <el-menu-item index="/Contactus">联系我们</el-menu-item>
              </el-menu>
            </div>
            <div class="hidden-sm-and-up">
              <div style="color: #ffffff">
                <el-dropdown trigger="click"  @command="handleCommand">
                    <span class="el-dropdown-link">
                     <img class="nav" src="../assets/image/nav.png" alt="">
                   </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="/">首页</el-dropdown-item>
                    <el-dropdown-item command="/about">关于我们</el-dropdown-item>
                    <el-dropdown-item command="/CompanyHonor">公司荣誉</el-dropdown-item>
                    <el-dropdown-item command="/Contactus">联系我们</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </el-row>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "NavMenu",
  data(){
    return{
      activeIndex: '/'
    }
  },
  methods:{
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.$router.push(key)
    },
    handleCommand(e){
      this.$router.push(e)
    }
  }
}
</script>

<style scoped>
.nav{
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.nav-top{
  height: 100px;
  width: 100%;
  border-top:1px solid #ffffff;
  color: #ffffff;
  text-align: center;
}
.logo{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}
.logo img{
  width: 40px;
  height: 40px;
  margin-right:10px ;
}
.home-position{
  width: 100%;
  z-index: 99;
  /*position: fixed;*/
  right: 0;
  top: 0;
  background:black;
  opacity:0.6
}
.home-NavMenu{
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}
</style>
