<template>
  <!--    footer-->
  <div class="footer">
    <div class="footer-text">
      <div>
        手机号：4000727687
      </div>
      <div>
        邮箱：yzkj@sxyzkj.net
      </div>
      <div>
        地址：山西省太原市小店区能源互联网大厦二层226室
      </div>
    </div>
    <div class="footer-record">
      翊泽科技有限公司  <a href="https://beian.miit.gov.cn/" target="_blank">晋ICP备2021014754号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer"
}
</script>

<style scoped>
.footer{
  width: 100%;
}
.footer-text{
  width: 100%;
  height: 50px;
  background: #232B2B;
  color: #708090;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
}
.footer-record{
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
}
</style>
