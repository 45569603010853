import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Layout from "../components/NavMenu"
Vue.use(VueRouter)
const routes = [
  {
    path: '/layout',
    component: Layout,
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: Home
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/Contactus',
        name: 'Contactus',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contactus.vue')
      },
      {
        path: '/CompanyHonor',
        name: 'CompanyHonor',
        component: () => import(/* webpackChunkName: "about" */ '../views/CompanyHonor.vue')
      }

    ]
  },

]

const router = new VueRouter({
  routes
})
router.beforeEach((to,from,next)=>{
  console.log(to,'1')
  console.log(from,'2')
  console.log(next,3)
  if(to.path=='/'){
    next("/Home")
  }else{
    next()
  }
})
export default router
